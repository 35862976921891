
class Tools {
    static LocationPathNameEqualsTo(uri) {
        const current_pathname = window.location.pathname.replace('/website/PelaajatComWebsite/build', '');
        if (current_pathname === uri) {
            return true;
        }
        if (current_pathname === encodeURIComponent(uri).replace('%2F', '/')) {
            return true;
        }
        return false;
    }

    static GetNextToggleValueForColumnFilter(value) {
        const [column, currentState] = value.split(':');
        let nextState;
        switch (currentState) {
            case 'true':
                nextState = 'false';
                break;
            case 'false':
                nextState = 'null';
                break;
            default: // covers 'null' and any other unexpected input
                nextState = 'true';
                break;
        }
        return `${column}:${nextState}`;
    }

    static GetIconForColumnFilter(value) {
        const [column, currentState] = (value ? value.split(':') : [null, null]);
        switch (currentState) {
            case 'true':
                return '🔽';
            case 'false':
                return '🔼';
            default: // covers 'null' and any other unexpected input
                return '↕️';
        }
    }

    static declarePageNoIndexToRobots(no_follow) {
        let robots_instructions = 'noindex';
        if (no_follow) {
            robots_instructions += ', nofollow';
        }
        Tools.updateMetaTagContent("robots", robots_instructions);
        Tools.updateMetaTagContent("googlebot", robots_instructions);
        Tools.updateMetaTagContent("bingbot", robots_instructions);
    }

    static updateMetaTagContent(name, content) {
        let meta = document.querySelector(`meta[name='${name}']`);
        if (meta) {
            meta.setAttribute("content", content);
        }
    }

    static getImageMimeTypeFromUri(img_url) {
        if (!img_url) {
            return null;
        }
        img_url = img_url.toLowerCase();
        if (img_url.indexOf('.png') !== -1) {
            return 'image/png';
        } else if (img_url.indexOf('.jpg') !== -1 || img_url.indexOf('.jpeg') !== -1) {
            return 'image/jpeg'
        } else if (img_url.indexOf('.tif') !== -1 || img_url.indexOf('.tiff') !== -1) {
            return 'image/tiff'
        } else if (img_url.indexOf('.webp') !== -1) {
            return 'image/webp'
        } else if (img_url.indexOf('.avif') !== -1) {
            return 'image/avif'
        } else if (img_url.indexOf('.apng') !== -1) {
            return 'image/apng'
        } else if (img_url.indexOf('.gif') !== -1) {
            return 'image/gif'
        } else if (img_url.indexOf('.svg') !== -1) {
            return 'image/svg+xml'
        }
    }
}

export default Tools;
